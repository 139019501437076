import React from "react";
import {getDateFromApiString} from "../../util/api";
import {ContentRedditPost} from "../../models/contentApi.model";
const { default: RedditIcon } =
    require("../../images/social/reddit-orange-icon.svg") as {
        default: string;
    };
const { default: DefaultUserIcon } =
    require("../../images/user-128.svg") as {
        default: string;
    };

type RedditProps = {
    contentData: ContentRedditPost;
    wrapperClass: string;
};

const ContentRedditItem = ({contentData, wrapperClass}: RedditProps) => {
    const userData = contentData.author
    let isAuthorMissing = false
    if (!userData) {
        isAuthorMissing = true
        console.log(contentData)
    }
    const userName = userData ? userData.username : 'Unknown';
    const postTime = getDateFromApiString(contentData.created_at).toLocal();
    return (
        <div className={wrapperClass}>
            <div className={"flex pt-2 pb-2"}>
                <div className={"flex-none pr-2 pt-1"}>
                    { isAuthorMissing ? (
                        <img
                            width={48}
                            height={48}
                            src={DefaultUserIcon}
                            className={"rounded-full bg-orange-300"}
                        />
                    ) : (
                        <a href={`https://reddit.com/u/${userName}`} target={"_blank"}>
                            <img
                                width={48}
                                height={48}
                                src={DefaultUserIcon}
                                className={"rounded-full bg-orange-300"}
                            />
                        </a>
                    )
                    }
                </div>
                <div className={"flex-initial pr-4"}>
                    <div className={"pt-2"}>
                        { isAuthorMissing ? (
                            <div className={"text-lg font-bold"}>{userName}</div>
                        ) : (
                            <a
                                className={"text-blue-700 hover:text-blue-900"}
                                href={`https://reddit.com/u/${userName}`}
                                target={"_blank"}
                            >
                                {userName}
                            </a>
                        )
                        }
                        <div className={"text-gray-600 text-sm pl-1"}>
                            r/{contentData.subreddit}
                        </div>
                    </div>
                </div>
                <div className={"flex-initial text-gray-400 text-sm pt-1 pl-1 lg:pl-4"}>
                    <div className={"pb-1"}>{postTime.toFormat("h:mm a")}</div>
                    <div>
                        {postTime.monthShort} {postTime.day}, {postTime.year}
                    </div>
                </div>
                <div className={"flex-grow pt-2"}>
                    <a
                        className={"inline-block float-right"}
                        target={"_blank"}
                        href={`${contentData.content_url}`}
                    >
                        <img width={45} height={45} src={RedditIcon}/>
                    </a>
                </div>
            </div>
            { contentData.title ? (<div className={"p-2 lg:p-6 font-bold text-lg"}>{contentData.title}</div>) : null }
            <div className={"p-2 lg:p-6"}>{contentData.text}</div>
        </div>
    );
};

export default ContentRedditItem;
