import {ContentMetrics} from "./content";
import {ContextTopicResult} from "./context.model";

enum DataSource {
    Reddit = 'DataSource.reddit',
    // Add other data sources as needed
}

export enum DataType {
    Tweet = 1,
    Discussions = 2,
    CustomUpload = 3,
    RedditPost = 4,
    Blogs = 5,
    News = 6,
    ArxivPaper = 7
}

enum RedditPostType {
    Link = 1,
    // Add other Reddit post types as needed
}

export type RedditAuthor = {
    id: number;
    identifier: string;
    updated: string | null;
    username: string;
}

export type TwitterAuthor = {
    id: number;
    twitter_id: number;
    name: string | null;
    handle: string;
    user_url: string | null;
    profile_image_url: string | null;
    created_at: Date | null;
    updated: Date;
    description: string | null;
    verified: boolean;
    followers_count: number;
    friends_count: number;
    listed_count: number;
    statuses_count: number;
}

export type Content = {
    content_type: number;
    created_at: string;
    data_source: DataSource;
    data_type: DataType;
    id: number;
    vendor_id_int: number | null;
    vendor_id_str: string;
}

export interface ContentPost {
    created_at: string;
    content: Content;
    text: string;
}

export interface ContentRedditPost extends ContentPost {
    author: RedditAuthor;
    content_url: string;
    date: string;
    downvote_count: number;
    has_link: boolean;
    has_subject: boolean;
    id: number;
    is_backfill: boolean;
    is_stream: boolean;
    karma: number;
    link_count: number;
    parent_reddit_id: string | null;
    parent_type: string | null;
    reddit_id: string;
    reddit_post_type: RedditPostType;
    source_id: string;
    subreddit: string;
    title: string;
    updated: string;
    upvote_count: number;
}

export interface ContentTweet extends ContentPost {
    author: TwitterAuthor;
    tweet_id: number;
    retweet_count: number;
    like_count: number;
    is_retweet: boolean;
    is_quote_status: boolean;
    in_reply_to_status_id: number | null;
    in_reply_to_user_id: number | null;
    quoted_status_id: number | null;
    quote_count: number;
    reply_count: number;
    followers_count: number;
    cashtag_count: number;
    has_media: boolean;
    has_poll: boolean;
    has_place: boolean;
    hashtag_count: number;
    keyword_count: number;
    impression_count: number;
    conversation_id: number | null;
    is_conversation: boolean;
    is_search: boolean;
    is_simple_import: boolean;
}

export type ArxivAuthor = {
    full_name: string;
    id: number;
}

export interface ContentArxivPaper extends ContentPost {
    arxiv_subject_hit_summary: string;
    abstract_summary: string;
    arxiv_id: string;
    title: string;
    author: {
        authors: ArxivAuthor[];
    };
    arxiv_updated: string;
}

export interface ContentEditorialSite {
    id: number;
    title: string | null;
    domain: string;
    domain_rank: number | null;
    image_url: string | null;
    description: string | null;
}

export interface ContentEditorialAuthor {
    id: number;
    identifier: string;
    site_id: number;
    site: ContentEditorialSite;
}

export interface ContentEditorialPost extends ContentPost {
    author: ContentEditorialAuthor;
    title: string;
    url: string;
    site: ContentEditorialSite;
}

export type RecentContentResponse = {
    content: ContentPost[];
    responseStatus: number;
}

export type ContentByDateResponse = {
    content: ContentPost[];
    isNext: boolean;
    nextOffset: number | null;
    responseStatus: number;
}

export type ContentMetricsResponse = {
    responseStatus: number;
} & ContentMetrics;

export type RecentContentTopicContextResultResponse = {
    responseStatus: number;
    summaries: ContextTopicResult[];
}
