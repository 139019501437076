import { DateTime } from 'luxon';

export const DEAULT_TIMEZONE = 'America/Los_Angeles'

export function getUserTimezone(): string {
    const zoneName = DateTime.local().zoneName;
    if (zoneName === 'system' || !zoneName) {
        return DEAULT_TIMEZONE;
    }
    return zoneName;
}
