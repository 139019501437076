import {API_ENDPOINT, ErrorType, getErrorFromResponse, getFetchAuthHeaders} from "../../util/api"
import React, { useCallback, useContext, useState } from "react";
import { AuthenticatedUserData, UserContext } from "../user/WithAuth";
import {RecentTweetContextResultResponse, TweetContextResultByDateResponse} from "../../models/twitterApi.model";
import {RecentContentTopicContextResultResponse} from "../../models/contentApi.model";


export type GetRecentTopicContextResultResponse = {
    error: null | ErrorType;
    execute: (subjectId: number, sinceMinutes?: number) => Promise<RecentContentTopicContextResultResponse | null>;
    isLoading: boolean;
    data: RecentTweetContextResultResponse | null;
};

export async function getRecentSubjectTopicContextResult(userData: AuthenticatedUserData, subjectId: number, sinceMinutes: number = 0): Promise<RecentTweetContextResultResponse> {
    let endpoint = `${API_ENDPOINT}/v1/content/topic/summaries/getSubjectContextTopics/recent/${subjectId}`
    if (sinceMinutes) {
        endpoint = `${endpoint}?since_minutes=${sinceMinutes}`
    }
    const response = await fetch(
        endpoint,
        await getFetchAuthHeaders(userData)
    );
    const responseData = await response.json() as RecentContentTopicContextResultResponse;
    responseData.responseStatus = response.status;
    return responseData;
}

export const useGetRecentTopicContextResults = (): GetRecentTopicContextResultResponse => {
    const [error, setError] = useState<ErrorType | null>(null);
    const [data, setData] = useState<RecentTweetContextResultResponse | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const userData = useContext(UserContext) as AuthenticatedUserData
    const execute = async (subjectId: number, sinceMinutes: number = 0) => {
        try {
            setIsLoading(true);
            const response = await getRecentSubjectTopicContextResult(userData, subjectId, sinceMinutes);
            const responseError = getErrorFromResponse(response.responseStatus);
            if (responseError) {
                setData(null)
                setError(responseError)
                setIsLoading(false);
                return null;
            }
            setData(response);
            setIsLoading(false);
            return response;
        } catch (e) {
            setData(null)
            setError(ErrorType.ClientError);
            setIsLoading(false);
            return null;
        }
    };

    return {
        isLoading,
        error,
        data,
        execute: useCallback(execute, []),
    };
};
