import { API_ENDPOINT, ErrorType, getFetchAuthHeaders, getErrorFromResponse } from "../../util/api";
import React, { useCallback, useContext, useState } from "react";
import { AuthenticatedUserData, UserContext } from "../user/WithAuth";
import {SubjectApiData, SubjectDetailsResponse} from "../../models/subjectApi.model";

export type GetDashboardDetailsResponse = {
    error: null | ErrorType;
    execute: () => Promise<SubjectApiData | null>;
    isLoading: boolean;
    data: SubjectApiData | null;
};

export async function getDashboardDetails(userData: AuthenticatedUserData, dashboardId: number | string): Promise<SubjectDetailsResponse> {
    let endpoint = `${API_ENDPOINT}/v1/subject/details/${dashboardId}`
    const response = await fetch(
        endpoint,
        await getFetchAuthHeaders(userData)
    );
    const responseData = await response.json() as SubjectApiData;
    return {
        responseStatus: response.status,
        details: responseData,
    };
}

const useDashboardDetails = (dashboardId: string | undefined): GetDashboardDetailsResponse => {
    const [error, setError] = useState<ErrorType | null>(null);
    const [data, setData] = useState<SubjectApiData | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const userData = useContext(UserContext) as AuthenticatedUserData
    const execute = async () => {
        try {
            setIsLoading(true);
            if (!dashboardId || parseInt(dashboardId) < 1) {
                setError(ErrorType.InvalidSymbol);
                setIsLoading(false);
                return null;
            }
            const response = await getDashboardDetails(userData, parseInt(dashboardId));
            const responseError = getErrorFromResponse(response.responseStatus);
            if (responseError) {
                setData(null)
                setError(responseError)
                setIsLoading(false);
                return null;
            }
            setData(response.details);
            setIsLoading(false);
            return response.details;
        } catch (e) {
            setError(ErrorType.ClientError);
            setIsLoading(false);
            return null;
        }
    };

    return {
        isLoading,
        error,
        data,
        execute: useCallback(execute, [dashboardId]),
    };
};

export default useDashboardDetails;
