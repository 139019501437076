import React from "react";

const LoadingText = ({
  width,
  height,
  innerClasses,
  outerClasses,
}: {
  width: number;
  height: number;
  innerClasses?: string[];
  outerClasses?: string[];
}) => {
  const defaultInnerClasses = ["bg-gray-100"];
  const defaultOuterClasses = ["animate-pulse"];
  if (innerClasses) {
    defaultInnerClasses.push(...innerClasses);
  }
  if (outerClasses) {
    defaultOuterClasses.push(...outerClasses);
  }
  return (
    <div className={defaultOuterClasses.join(" ")}>
      <div
        style={{ height, width }}
        className={defaultInnerClasses.join(" ")}
      ></div>
    </div>
  );
};

export default LoadingText;
