import {
    API_ENDPOINT,
    convertBigIntToStringFromResponse,
    ErrorType,
    getErrorFromResponse,
    getFetchAuthHeaders
} from "../../util/api"
import React, { useCallback, useContext, useState } from "react";
import { AuthenticatedUserData, UserContext } from "../user/WithAuth";
import {ContentByDateResponse, RecentContentResponse} from "../../models/contentApi.model";
import {getUserTimezone} from "../../util/dateUtil";


export type GetRecentContentResponse = {
    error: null | ErrorType;
    execute: (
        subjectId: number,
        topicId?: number | null,
        sentiment?: string | null,
        contentType?: string | null,
        sinceMinutes?: number,
    ) => Promise<RecentContentResponse | null>;
    isLoading: boolean;
    data: RecentContentResponse | null;
};


export type GetContentByDateResponse = {
    error: null | ErrorType;
    execute: (
        subjectId: number,
        date: string,
        offset?: number | null,
        topicId?: number | null,
        sentiment?: string | null,
        contentType?: string | null,
    ) => Promise<ContentByDateResponse | null>;
    isLoading: boolean;
    data: ContentByDateResponse | null;
};


export async function getSubjectContentByDate(
    userData: AuthenticatedUserData,
    subjectId: number,
    date: string,
    offset: number | null = null,
    topicId: number | null = null,
    sentiment: string | null = null,
    contentType: string | null = null,
): Promise<ContentByDateResponse> {
    let endpoint = `${API_ENDPOINT}/v1/content/posts/getSubjectPosts/byDate/${subjectId}/${date}`
    let querySeparator = '?'
    const tz = getUserTimezone()
    if (tz) {
        endpoint = `${endpoint}${querySeparator}timezone_iana=${tz}`
        querySeparator = '&'
    }
    if (offset) {
        endpoint = `${endpoint}${querySeparator}offset=${offset}`
        querySeparator = '&'
    }
    if (topicId) {
        endpoint = `${endpoint}${querySeparator}topic_id=${topicId}`
        querySeparator = '&'
    }
    if (sentiment) {
        endpoint = `${endpoint}${querySeparator}sentiment=${sentiment}`
        querySeparator = '&'
    }
    if (contentType) {
        endpoint = `${endpoint}${querySeparator}data_type=${contentType}`
    }
    const response = await fetch(
        endpoint,
        await getFetchAuthHeaders(userData)
    );
    const responseData = await convertBigIntToStringFromResponse(response) as ContentByDateResponse;
    responseData.responseStatus = response.status;
    return responseData;
}


export async function getRecentSubjectContent(
    userData: AuthenticatedUserData,
    subjectId: number,
    topicId: number | null = null,
    sentiment: string | null = null,
    contentType: string | null = null,
    sinceMinutes: number = 0,
): Promise<RecentContentResponse> {
    let endpoint = `${API_ENDPOINT}/v1/content/posts/getSubjectPosts/recent/${subjectId}`
    let querySeparator = '?'
    if (sinceMinutes) {
        endpoint = `${endpoint}${querySeparator}since_minutes=${sinceMinutes}`
        querySeparator = '&'
    }
    if (topicId) {
        endpoint = `${endpoint}${querySeparator}topic_id=${topicId}`
        querySeparator = '&'
    }
    if (sentiment) {
        endpoint = `${endpoint}${querySeparator}sentiment=${sentiment}`
        querySeparator = '&'
    }
    if (contentType) {
        endpoint = `${endpoint}${querySeparator}data_type=${contentType}`
    }
    const response = await fetch(
        endpoint,
        await getFetchAuthHeaders(userData)
    );
    const responseData = await convertBigIntToStringFromResponse(response) as RecentContentResponse;
    responseData.responseStatus = response.status;
    return responseData;
}

export const useGetContentByDate = (): GetContentByDateResponse => {
    const [error, setError] = useState<ErrorType | null>(null);
    const [data, setData] = useState<ContentByDateResponse | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const userData = useContext(UserContext) as AuthenticatedUserData
    const execute = async (
        subjectId: number,
        date: string,
        offset: number | null = null,
        topicId: number | null = null,
        sentiment: string | null = null,
        contentType: string | null = null,
    ) => {
        try {
            setIsLoading(true);
            const response = await getSubjectContentByDate(userData, subjectId, date, offset, topicId, sentiment, contentType);
            const responseError = getErrorFromResponse(response.responseStatus);
            if (responseError) {
                setData(null)
                setError(responseError)
                setIsLoading(false);
                return null;
            }
            setData(response);
            setIsLoading(false);
            return response;
        } catch (e) {
            setData(null)
            setError(ErrorType.ClientError);
            setIsLoading(false);
            return null;
        }
    };

    return {
        isLoading,
        error,
        data,
        execute: useCallback(execute, []),
    };
}

export const useGetRecentContentData = (): GetRecentContentResponse => {
    const [error, setError] = useState<ErrorType | null>(null);
    const [data, setData] = useState<RecentContentResponse | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const userData = useContext(UserContext) as AuthenticatedUserData
    const execute = async (
        subjectId: number,
        topicId: number | null = null,
        sentiment: string | null = null,
        contentType: string | null = null,
        sinceMinutes: number = 0,
    ) => {
        try {
            setIsLoading(true);
            const response = await getRecentSubjectContent(userData, subjectId, topicId, sentiment, contentType, sinceMinutes);
            const responseError = getErrorFromResponse(response.responseStatus);
            if (responseError) {
                setData(null)
                setError(responseError)
                setIsLoading(false);
                return null;
            }
            setData(response);
            setIsLoading(false);
            return response;
        } catch (e) {
            setData(null)
            setError(ErrorType.ClientError);
            setIsLoading(false);
            return null;
        }
    };

    return {
        isLoading,
        error,
        data,
        execute: useCallback(execute, []),
    };
};
