import {API_ENDPOINT, ErrorType, getErrorFromResponse, getFetchAuthHeaders} from "../../util/api"
import React, { useCallback, useContext, useState } from "react";
import { AuthenticatedUserData, UserContext } from "../user/WithAuth";
import {getUserTimezone} from "../../util/dateUtil";
import {ContentMetricsResponse} from "../../models/contentApi.model";
import {graphStatic} from "./fakeGraph";


export enum GraphIntervalType {
    DAILY = 'daily',
    DAILY_BY_DATE = 'daily_by_date',
    HOURLY = 'hourly',
    HOURLY_BY_DATE = 'hourly_by_date',
    WEEKLY = 'weekly',
    WEEKLY_BY_DATE = 'weekly_by_date'
}

export const GraphByDateEndpoints = [
    GraphIntervalType.DAILY_BY_DATE,
    GraphIntervalType.HOURLY_BY_DATE,
    GraphIntervalType.WEEKLY_BY_DATE
]

const endpointMap = {
    [GraphIntervalType.HOURLY]: 'getHourlyGraph',
    [GraphIntervalType.HOURLY_BY_DATE]: 'getHourlyGraphByDate',
    [GraphIntervalType.DAILY]: 'getDailyGraph',
    [GraphIntervalType.DAILY_BY_DATE]: 'getDailyGraphByDate',
    [GraphIntervalType.WEEKLY]: 'getWeeklyGraph',
    [GraphIntervalType.WEEKLY_BY_DATE]: 'getWeeklyGraphByDate',
}


export type GetContentMetricsResponse = {
    error: null | ErrorType;
    execute: (subjectId: number, date?: string) => Promise<ContentMetricsResponse | null>;
    isLoading: boolean;
    data: ContentMetricsResponse | null;
};


export async function getSubjectContentMetrics(
    userData: AuthenticatedUserData,
    subjectId: number,
    graphType: GraphIntervalType,
    date?: string,
): Promise<ContentMetricsResponse> {
    const graphEndpoint = endpointMap[graphType]
    let endpoint = `${API_ENDPOINT}/v1/content/stats/${graphEndpoint}/${subjectId}`
    const user_tz = getUserTimezone()
    if (date) {
        endpoint += `/${date}`;
    }
    endpoint += `?timezone_iana=${user_tz}`;
    const response = await fetch(
        endpoint,
        await getFetchAuthHeaders(userData)
    );
    const responseData = await response.json() as ContentMetricsResponse;
    // const responseData = graphStatic as ContentMetricsResponse;
    responseData.responseStatus = response.status;
    return responseData;
}


export const useGetSubjectContentMetricsResult = (graphType: GraphIntervalType): GetContentMetricsResponse => {
    const [error, setError] = useState<ErrorType | null>(null);
    const [data, setData] = useState<ContentMetricsResponse | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const userData = useContext(UserContext) as AuthenticatedUserData
    const execute = async (subjectId: number, date?: string) => {
        try {
            setIsLoading(true);
            const response = date
                ? await getSubjectContentMetrics(userData, subjectId, graphType, date)
                : await getSubjectContentMetrics(userData, subjectId, graphType);
            const responseError = getErrorFromResponse(response.responseStatus);
            if (responseError) {
                setData(null)
                setError(responseError)
                setIsLoading(false);
                return null;
            }
            setData(response);
            setIsLoading(false);
            return response;
        } catch (e) {
            setData(null)
            setError(ErrorType.ClientError);
            setIsLoading(false);
            return null;
        }
    };

    return {
        isLoading,
        error,
        data,
        execute: useCallback(execute, []),
    };
}
