import React from "react";
import {getDateFromApiString} from "../../util/api";
import {ContentEditorialPost, DataType} from "../../models/contentApi.model";
import {NewspaperIcon, ChatAltIcon, RssIcon} from "@heroicons/react/outline";

const { default: DefaultUserIcon } =
    require("../../images/user-128.svg") as {
        default: string;
    };

type PostProps = {
    contentData: ContentEditorialPost;
    wrapperClass: string;
};

const getDataIcon = (typeId: number) => {
    const defaultIcon = (
        <div className={"w-12 h-12 rounded-full bg-green-600 flex items-center pointer-events-none pl-2"} >
            <ChatAltIcon className="h-8 w-8 text-white"
                         aria-hidden="true"  />
        </div>
    )
    // forums
    if (typeId === DataType.Discussions) {
        return defaultIcon
    }
    if (typeId === DataType.Blogs) {
        return (
            <div className={"w-12 h-12 rounded-full bg-amber-600 flex items-center pointer-events-none pl-2"} >
                <RssIcon className="h-8 w-8 text-white"
                             aria-hidden="true"  />
            </div>
        )
    }
    if (typeId === DataType.News) {
        return (
            <div className={"w-12 h-12 rounded-full bg-violet-700 flex items-center pointer-events-none pl-2"} >
                <NewspaperIcon className="h-8 w-8 text-white"
                         aria-hidden="true"  />
            </div>
        )
    }
    return defaultIcon;
}

const getDataColor = (typeId: number) => {
    const defaultColor = "bg-green-400"
    if (typeId === DataType.Discussions) {
        return defaultColor
    }
    if (typeId === DataType.Blogs) {
        return "bg-amber-400"
    }
    if (typeId === DataType.News) {
        return "bg-violet-400"
    }
    return defaultColor
}

const getDomain = (url: string): string | null => {
    if (!url) {
        return null;
    }
    const parsedUrl = new URL(url)
    return parsedUrl?.hostname ?? null;
}


const ContentEditorialItem = ({contentData, wrapperClass}: PostProps) => {
    const postTime = getDateFromApiString(contentData.created_at).toLocal();
    const hasDomain = !!getDomain(contentData.url);
    return (
        <div className={wrapperClass}>
            <div className={"flex pt-2 pb-2"}>
                <div className={"flex-none pr-2 pt-1"}>
                        <img
                            width={48}
                            height={48}
                            src={DefaultUserIcon}
                            className={"rounded-full " + getDataColor(contentData.content.data_type)}
                        />
                </div>
                <div className={"flex-initial pr-4"}>
                    { hasDomain ? (
                        <div className={"text-lg font-semibold pt-3"}>{getDomain(contentData.url)}</div>
                    ) : null}
                </div>
                <div className={"flex-initial text-gray-400 text-sm pt-1 pl-1 lg:pl-4"}>
                    <div className={"pb-1"}>{postTime.toFormat("h:mm a")}</div>
                    <div>
                        {postTime.monthShort} {postTime.day}, {postTime.year}
                    </div>
                </div>
                <div className={"flex-grow pt-2"}>
                    <a
                        className={"inline-block float-right"}
                        target={"_blank"}
                        href={`${contentData.url}`}
                    >
                        {getDataIcon(contentData.content.data_type)}
                    </a>
                </div>
            </div>
            { contentData.title ? (<div className={"p-2 lg:p-6 font-bold text-lg"}>{contentData.title}</div>) : null }
            <div className={"p-2 lg:p-6"}>{contentData.text}</div>
        </div>
    );
};

export default ContentEditorialItem;
