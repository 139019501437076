import {API_ENDPOINT, ErrorType, getErrorFromResponse, getFetchAuthHeaders} from "../../util/api"
import React, { useCallback, useContext, useState } from "react";
import { AuthenticatedUserData, UserContext } from "../user/WithAuth";
import {SubjectTopLinkResponse} from "../../models/subjectApi.model";


export type GetSubjectTopLinksResultResponse = {
    error: null | ErrorType;
    execute: (subjectId: number) => Promise<SubjectTopLinkResponse | null>;
    isLoading: boolean;
    data: SubjectTopLinkResponse | null;
};


export async function getTopLinks(userData: AuthenticatedUserData, subjectId: number): Promise<SubjectTopLinkResponse> {
    let endpoint = `${API_ENDPOINT}/v1/twitter/tweet/getTopLinks/${subjectId}`
    const response = await fetch(
        endpoint,
        await getFetchAuthHeaders(userData)
    );
    const responseData = await response.json() as SubjectTopLinkResponse;
    responseData.responseStatus = response.status;
    return responseData;
}

export const useGetTopLinks = (): GetSubjectTopLinksResultResponse => {
    const [error, setError] = useState<ErrorType | null>(null);
    const [data, setData] = useState<SubjectTopLinkResponse | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const userData = useContext(UserContext) as AuthenticatedUserData
    const execute = async (subjectId: number) => {
        try {
            setIsLoading(true);
            const response = await getTopLinks(userData, subjectId);
            const responseError = getErrorFromResponse(response.responseStatus);
            if (responseError) {
                setData(null)
                setError(responseError)
                setIsLoading(false);
                return null;
            }
            setData(response);
            setIsLoading(false);
            return response;
        } catch (e) {
            setData(null)
            setError(ErrorType.ClientError);
            setIsLoading(false);
            return null;
        }
    };

    return {
        isLoading,
        error,
        data,
        execute: useCallback(execute, []),
    };
};
