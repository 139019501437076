import React, {useEffect, useState} from "react";
import TileRounded from "../layout/TileRounded";
import { PieChart } from "react-minimal-pie-chart";
import {DashboardViewProps} from "../dashboards/dashboardTypes";
import {ErrorType, getDateFromApiString, SummaryType} from "../../util/api";
import {useGetTopicStats} from "./getTopics";
import {SubjectTopicStatsResponse} from "../../models/subjectApi.model";
import Spinner from "../animation/Spinner";
import {useGetRecentTopicContextResults} from "./getTopicContext";
import {ContextTopicResult} from "../../models/context.model";
import OpenAiResponseText from "../openai/OpenAiResponseText";

interface TopicColor {
    arcColor: string;
    bgColor: string;
}

const topics: TopicColor[] = [
    {
        arcColor: "rgb(192,38,211)",
        bgColor: "rgb(245,208,254)",
    },
    {
        arcColor: "rgb(59 130 246)",
        bgColor: "rgb(191 219 254)",
    },
    {
        arcColor: "rgb(20 184 166)",
        bgColor: "rgb(153 246 228)",
    },
    {
        arcColor: "rgb(139 92 246)",
        bgColor: "rgb(221 214 254)",
    },
    {
        arcColor: "rgb(8,145,178)",
        bgColor: "rgb(165,243,252)",
    },
    {
        arcColor: "rgb(234,88,12)",
        bgColor: "rgb(254,215,170)",
    },
    {
        arcColor: "rgb(219,39,119)",
        bgColor: "rgb(251,207,232)",
    },
    {
        arcColor: "rgb(234,179,8)",
        bgColor: "rgb(254,240,138)",
    },
    {
        arcColor: "rgb(6,182,212)",
        bgColor: "rgb(165,243,252)",
    },
    {
        arcColor: "rgb(79,70,229)",
        bgColor: "rgb(199,210,254)",
    }
];

const getTopicColorFromIndex = (index: number): TopicColor  => {
    let colorIndex: number = index
    if (index > 9) {
        colorIndex = index % 10
    }
    return topics[colorIndex]
}


const ContentTopics = ({ isSubjectLoading, dashboardId, subjectData }: DashboardViewProps) => {

    const [isTopicsLoaded, setIsTopicsLoaded] = useState<boolean>(false);
    const [isTopicsContextLoaded, setIsTopicsContextLoaded] = useState<boolean>(false);
    const [topicsData, setTopicsData] = useState<SubjectTopicStatsResponse | null>(null);
    const [doDisplayMetrics, setDoDisplayMetrics] = useState<boolean>(true);
    const [topicSelected, setTopicSelected] = useState<number>(0);
    const [summaryData, setSummaryData] = useState<ContextTopicResult[] | null>(null);

    const {
        isLoading,
        data,
        error,
        execute,
    } = useGetTopicStats();

    const {
        error: contextError,
        execute: contextExecute,
        isLoading: contextLoading,
        data: contextData,
    } = useGetRecentTopicContextResults()

    useEffect(() => {
        if (!isSubjectLoading) {
            execute(dashboardId).then((response) => {
                setTopicsData(response ?? null)
                setIsTopicsLoaded(true)
            })
        }
    }, [isSubjectLoading, dashboardId]);

    useEffect(() => {
        if (!isSubjectLoading) {
            contextExecute(dashboardId).then((response) => {
                if (response?.summaries && response.summaries.length > 0) {
                    setSummaryData(response.summaries)
                    setTopicSelected(response.summaries[0].topic_id)
                }
                setIsTopicsContextLoaded(true)
            })
        }
    }, [isSubjectLoading, dashboardId]);

    const loadingJsx = (
        <div className={"flex items-center"}>
            <div className={"m-auto pt-16 pb-16"}>
                <Spinner width={200} height={200} />
            </div>
        </div>
    );

    const noSummariesJsx = (message: string) => {
        return (
            <div
                className={
                    "m-auto pt-16 pb-16 text-center text-xl text-semi-bold text-gray-400"
                }
            >
                {message}
            </div>
        );
    };

    const getTopicOptions = () => {
        const options: JSX.Element[] = []
        if (isSubjectLoading) {
            options.push(
                <option key={'0'} value={'0'}>- Any -</option>
            )
            return options
        }
        const topics = subjectData?.topics
        if (!topics || topics.length === 0) {
            options.push(
                <option key={'0'} value={'0'}>- Any -</option>
            )
            return options
        }
        console.log(topics)
        console.log(summaryData)
        for (const topic of topics) {
            options.push(
                <option key={`${topic.id}`} value={`${topic.id}`}>{topic.displayLabel}</option>
            )
        }
        return options
    }

    const getTopicContext = () => {
        if (!isTopicsContextLoaded) {
            return loadingJsx
        }
        if (contextError) {
            return noSummariesJsx("an unexpected error occurred while retrieving topic insights")
        }
        if (!summaryData || summaryData.length === 0) {
            return noSummariesJsx('no topic insights found')
        }
        const summary = summaryData.find(value => value.topic_id == topicSelected)
        if (!summary) {
            return noSummariesJsx('not enough data for topic insight')
        }
        const startDate = getDateFromApiString(summary.start_date).toLocal()
        const endDate = getDateFromApiString(summary.end_date).toLocal()
        const startDateText = `${startDate.monthShort} ${startDate.day}, ${startDate.year} ${startDate.toFormat("h:mm a")}`
        const endDateText = `${endDate.monthShort} ${endDate.day}, ${endDate.year} ${endDate.toFormat("h:mm a")}`
        const contextFields = [];
        let fieldIndex = 0;
        if (summary.context_fields && summary.context_fields.length > 0) {
            for (const field of summary.context_fields) {
                fieldIndex++
                contextFields.push(
                    <div key={`field-${fieldIndex}`}>
                        <h1 className={"text-xl font-bold"}>{field.context_label}</h1>
                        <div>
                            <OpenAiResponseText text={field.context_output}  className={"p-1"}  elementClassName={""}/>
                        </div>
                    </div>
                )
            }
        }
        const summaryInputOptions = []
        let summarySelectJsx = null
        let summaryTextJsx = (
                <OpenAiResponseText text={summary.context_outputs[0].output}  className={"p-1"}  elementClassName={""}/>
        )
        return (
            <div className={"border-b-2 border-gray-100 pb-4 pt-2"}>
                <div className={"pb-4 flex"}>
                    <div className={"flex-grow pt-2"}>
                        <h1 className={"text-xl font-bold "}>Insights</h1>
                        <div className={"text-gray-500 text-md pb-2 pt-2"}>
                            Using data from {startDateText} to {endDateText}
                        </div>
                    </div>
                    <div>
                        {summarySelectJsx}
                    </div>
                </div>
                <div>
                    {summaryTextJsx}
                </div>
                {contextFields}
            </div>
        )
    }

    const getSummary = (): JSX.Element[] | JSX.Element => {
        return (
            <div>
                <div>
                    <label
                        htmlFor={'topic-id-selection'}
                        className="block text-sm font-medium text-gray-700"
                    >
                        Topic Shown
                    </label>
                    <select
                        defaultValue={`${topicSelected}`}
                        onChange={(e) => {
                            setTopicSelected(parseInt(e.target.value))
                        }}
                        name={'topic-id-selection'}
                        id={'topic-id-selection'}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md max-w-[230px]"
                    >
                        {getTopicOptions()}
                    </select>
                </div>
                <div>
                    {getTopicContext()}
                </div>
            </div>
        )
    }

    const getCharts = (): JSX.Element[] | JSX.Element => {

        const defaultClass = "flex p-4 pt-6"
        const manyTopicsClass = "grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 pt-4"

        if (!isTopicsLoaded || isSubjectLoading) {
            return (<div className={defaultClass}>{loadingJsx}</div>);
        }
        if (error === ErrorType.NotFound) {
            return (<div className={defaultClass}>{noSummariesJsx('no topics found')}</div>);
        }
        if (error) {
            return (<div className={defaultClass}>{noSummariesJsx('an error occurred while retrieving topics')}</div>)
        }
        if (!topicsData) {
            return (<div className={defaultClass}>{noSummariesJsx('no topics found')}</div>)
        }

        const charts = [];
        let index = 0;
        topicsData.topics.sort((a, b) => b.displayPriority - a.displayPriority);
        for (const topic of topicsData.topics) {
            const percentage = topicsData.total_mentions ? ((topic.mentions / topicsData.total_mentions) * 100) : 0
            const empty = 100 - percentage;
            const topicColor = getTopicColorFromIndex(index)
            charts.push(
                <div key={`topic-${topic.id}`}>
                    <div className={"px-3 pb-2"}>
                        <PieChart
                            data={[
                                { value: percentage, color: topicColor.arcColor, display: true },
                                { value: empty, color: topicColor.bgColor },
                            ]}
                            totalValue={100}
                            startAngle={-90}
                            lineWidth={20}
                            label={({ dataEntry }) =>
                                dataEntry.display ? `${dataEntry.value.toFixed(2)}%` : null
                            }
                            labelStyle={{
                                fontSize: "12px",
                                fontFamily: "sans-serif",
                                fill: "black",
                            }}
                            labelPosition={0}
                        />
                    </div>
                    <div className={"text-sm text-center text-gray-500"}>
                        <span>{topic.label}</span>
                    </div>
                </div>
            );
            index++
        }
        return (<div className={index >= 4 ? manyTopicsClass : defaultClass}>{charts}</div>);
    }

    return (
        <TileRounded classArray={['h-[680px]', 'overflow-y-scroll']} tileType={"main"}>
            <div className={"flex"}>
                <div className={"flex-grow pt-2"}>
                    <span className={"text-lg"}>Topics Discussed (Last 7 Days)</span>
                </div>
                <div>
                    <label
                        htmlFor={'topic-display-selection'}
                        className="block text-sm font-medium text-gray-700"
                    >
                        Topic Display
                    </label>
                    <select
                        defaultValue={doDisplayMetrics ? "1" : "0"}
                        onChange={(e) => {
                            setDoDisplayMetrics(!!parseInt(e.target.value))
                        }}
                        name={'topic-display-selection'}
                        id={'topic-display-selection'}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md max-w-[230px]"
                    >
                        <option value={"1"}>Metrics</option>
                        <option value={"0"}>Insights</option>
                    </select>
                </div>
            </div>
            <div className={"pt-4"}>
                {doDisplayMetrics ? getCharts() : getSummary()}
            </div>
        </TileRounded>
    );
};

export default ContentTopics;
