import React from "react";
import {getDateFromApiString} from "../../util/api";
import {ContentPost} from "../../models/contentApi.model";
const { default: RedditIcon } =
    require("../../images/social/reddit-orange-icon.svg") as {
        default: string;
    };
const { default: DefaultUserIcon } =
    require("../../images/user-128.svg") as {
        default: string;
    };

type PostProps = {
    contentData: ContentPost;
    wrapperClass: string;
};

const ContentGenericItem = ({contentData, wrapperClass}: PostProps) => {
    const postTime = getDateFromApiString(contentData.created_at).toLocal();
    return (
        <div className={wrapperClass}>
            <div className={"flex pt-2 pb-2"}>
                <div className={"flex-none pr-2 pt-1"}>
                    <img
                        width={48}
                        height={48}
                        src={DefaultUserIcon}
                        className={"rounded-full bg-orange-300"}
                    />
                </div>
                <div className={"flex-initial pr-4"}>
                    {/*<div className={"text-lg font-semibold"}>{userName}</div>*/}
                    {/*<div>*/}
                    {/*    <a*/}
                    {/*        className={"text-blue-700 hover:text-blue-900"}*/}
                    {/*        href={`https://reddit.com/u/${userName}`}*/}
                    {/*        target={"_blank"}*/}
                    {/*    >*/}
                    {/*        {userName}*/}
                    {/*    </a>*/}
                    {/*</div>*/}
                </div>
                <div className={"flex-initial text-gray-400 text-sm pt-1 pl-1 lg:pl-4"}>
                    <div className={"pb-1"}>{postTime.toFormat("h:mm a")}</div>
                    <div>
                        {postTime.monthShort} {postTime.day}, {postTime.year}
                    </div>
                </div>
            </div>
            <div className={"p-2 lg:p-6"}>{contentData.text}</div>
        </div>
    );
};

export default ContentGenericItem;
