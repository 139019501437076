import React, {useEffect, useState} from "react";
import {DashboardViewProps} from "../dashboards/dashboardTypes";
import {SubjectTopLinkResponse} from "../../models/subjectApi.model";
import Spinner from "../animation/Spinner";
import {useGetTopLinks} from "./getTopLinks";
import TileRounded from "../layout/TileRounded";
import {ErrorType} from "../../util/api";
import {Dialog} from "@headlessui/react";
import { XIcon } from '@heroicons/react/outline';

type CurrentLink = {
    url: string;
    title: string;
}

const TopLinks = ({ isSubjectLoading, dashboardId }: DashboardViewProps) => {
    const [isTopLinksLoaded, setIsTopLinksLoaded] = useState<boolean>(false);
    const [linksData, setLinksData] = useState<SubjectTopLinkResponse | null>(null);
    const [isOpenLinkDialog, setIsOpenLinkDialog] = useState<boolean>(false)
    const [currentLink, setCurrentLink] = useState<CurrentLink>({url: '', title: ''})
    const {
        isLoading,
        data,
        error,
        execute,
    } = useGetTopLinks();

    useEffect(() => {
        if (!isSubjectLoading) {
            execute(dashboardId).then((response) => {
                setLinksData(response ?? null)
                setIsTopLinksLoaded(true)
            })
        }
    }, [isSubjectLoading, dashboardId]);

    const loadingJsx = (
        <div className={"flex items-center"}>
            <div className={"m-auto pt-16 pb-16"}>
                <Spinner width={200} height={200} />
            </div>
        </div>
    );

    const noLinksJsx = (message: string) => {
        return (
            <div
                className={
                    "m-auto pt-16 pb-16 text-center text-xl text-semi-bold text-gray-400"
                }
            >
                {message}
            </div>
        );
    };

    const getTopLinks = () => {
        if (!isTopLinksLoaded || isSubjectLoading) {
            return loadingJsx;
        }
        if (error === ErrorType.NotFound) {
            return noLinksJsx('no links found');
        }
        if (error) {
            return noLinksJsx('an error occurred while retrieving links');
        }
        if (!linksData || linksData?.top_10_links.length === 0) {
            return noLinksJsx('no links found');
        }
        const { top_10_links } = linksData
        return (
            <>
                <div className="p-4">
                    <div className="">
                        {top_10_links.map((link, index) => (
                            <div key={index}
                                 className="border p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow mb-6">
                                <button onClick={
                                    () => {
                                        setCurrentLink({
                                            title: link.title || link.display_url || link.url,
                                            url: link.url
                                        })
                                        setIsOpenLinkDialog(true)
                                    }
                                }
                                   className="text-blue-600 hover:text-blue-800">
                                    {link.title || link.display_url || link.url}
                                </button>
                                {link.description && <p className="text-sm  text-gray-400 mt-2">
                                    {link.description && link.description.length > 200
                                        ? `${link.description.substring(0, 200)}...`
                                        : link.description}
                                </p>}
                                <div className={"text-sm text-gray-400 mt-2"}>
                                    <p>
                                        Shared {link.count} {link.count > 1 ? 'times' : 'time'}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </>
        );
    }

    return (
        <TileRounded tileType={"main"}>
            <span className={"text-xl"}>Top Shared Links (Last 7 Days)</span>
            <div className={"max-h-[450px] overflow-auto p-4 pt-6"}>{getTopLinks()}</div>
            <Dialog
                open={isOpenLinkDialog}
                onClose={() => setIsOpenLinkDialog(false)}
                className="relative z-50"
            >
                <div className="fixed inset-0 bg-black/30" aria-hidden="true"/>
                <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                    <Dialog.Panel className="rounded bg-white p-8">
                        <button
                            onClick={() => setIsOpenLinkDialog(false)}
                            className="relative -top-9 -right-9 float-right rounded-full bg-blue-600 hover:bg-blue-800 text-white border-4 border-white focus:outline-none"
                            aria-label="Close dialog"
                        >
                            <XIcon className="h-7 w-7"/>
                        </button>
                        <Dialog.Title className={"text-center text-red-700"}>Warning: You are leaving Runic</Dialog.Title>
                        <Dialog.Description className={"max-w-md text-center text-sm text-gray-500 pt-3 pb-2"}>
                            You are leaving the Runic application to visit a third party's website. Please make sure you
                            trust this domain before proceeding as not all links shared on social media are secure.
                        </Dialog.Description>
                        <div className={"text-md max-w-md text-center"}>
                            <a className={"text-blue-600 hover:text-blue-800"} target={"_blank"}
                               href={currentLink.url}>{currentLink.url}</a>
                        </div>
                    </Dialog.Panel>
                </div>
            </Dialog>
        </TileRounded>
    );
}

export default TopLinks;