import React, {ChangeEventHandler, Fragment, useEffect, useState,} from "react";
import TileRounded from "../layout/TileRounded";
import TileBgNone from "../layout/TileBgNone";
import Spinner from "../animation/Spinner";
import "react-day-picker/dist/style.css";
import {DashboardViewProps} from "../dashboards/dashboardTypes";
import {useGetContextResultByDate, useGetRecentContextResults} from "./getContext";
import {ContextResult} from "../../models/context.model";
import {ErrorType, getDateFromApiString, SummaryType} from "../../util/api";
import OpenAiResponseText from "../openai/OpenAiResponseText";
import {format, isValid, parse} from "date-fns";
import {DayPicker, SelectSingleEventHandler} from "react-day-picker";
import {ExclamationCircleIcon} from "@heroicons/react/solid";
import {Popover, Transition} from "@headlessui/react";
import {CalendarIcon} from "@heroicons/react/outline";

const sampleSummary = "### Summary of Papers and NVIDIA Technology Usage\n" +
    "\n" +
    "#### *Neurolgp-sm: Scalable Surrogate-Assisted Neuroevolution for Deep Neural Networks*\n" +
    "\n" +
    "- **Abstract Summary**: This paper introduces a novel surrogate-assisted evolutionary algorithm termed Neuro-Linear Genetic Programming Surrogate Model (NeuroLGP-SM), aimed at optimizing large deep neural networks (DNNs). This approach reduces computational costs significantly by accurately estimating the fitness of DNNs without requiring full evaluations. NeuroLGP-SM performs competitively or better than other methods, offering a 25% energy efficiency improvement compared to its base version.\n" +
    "  \n" +
    "- **Hardware and Technology Summary**:\n" +
    "  - **NVIDIA Tesla V100 GPUs**: Each experimental run used a single Tesla V100 GPU with 16GB of RAM, significantly reducing computation time.\n" +
    "  - **Intel Xeon Gold 6148 CPUs**: Supported training of the surrogate model.\n" +
    "  - **Kay Supercomputer**: Provided by the Irish Centre for High-End Computing (ICHEC) for parallel processing, aiding in efficient computation.\n" +
    "\n" +
    "#### *Mulan-WC: Multi-Robot Localization Uncertainty-Aware Active NeRF with Wireless Coordination*\n" +
    "\n" +
    "- **Abstract Summary**: Mulan-WC is a multi-robot 3D reconstruction framework leveraging wireless signal-based coordination and neural radiance fields (NeRF). Addressing issues like inter-robot pose estimation and localization uncertainty, Mulan-WC incorporates these localizations into NeRF training to mitigate inaccuracies. The system effectively chooses the next best view to accelerate convergence and improve 3D reconstruction quality.\n" +
    "  \n" +
    "- **Hardware and Technology Summary**:\n" +
    "  - **CUDA-Accelerated Ray Marching**: Utilized within a PyTorch framework for efficient computation.\n" +
    "  - **NVIDIA RTX 6000**: All evaluations were performed using this GPU to handle intensive tasks like NeRF training and active view selection.\n" +
    "\n" +
    "#### *Oc4-ReID: Occluded Cloth-Changing Person Re-Identification*\n" +
    "\n" +
    "- **Abstract Summary**: This study addresses the challenges in identifying individuals in changing clothes and occluded scenarios. It introduces new datasets, techniques, and benchmarks to handle these issues effectively. The proposed method, integrating features like Train-Test Micro Granularity Screening and Part-Robust Triplet loss, outperforms existing methods on various datasets.\n" +
    "  \n" +
    "- **Hardware and Technology Summary**:\n" +
    "  - **NVIDIA RTX 4090 GPUs**: Two GPUs with 24GB of memory each were used for efficient handling and training of deep learning models.\n" +
    "  - **PyTorch Framework**: For developing and training models, with ResNet-50 as the backbone for feature extraction.\n" +
    "  - **Data Augmentation**: Techniques like random horizontal flipping, cropping, and erasing were used to enhance robustness.\n" +
    "\n" +
    "#### *Leveraging Diverse Semantic-Based Audio Pretrained Models for Singing Voice Conversion*\n" +
    "\n" +
    "- **Abstract Summary**: This paper evaluates and proposes improvements in singing voice conversion (SVC) through diverse semantic-based feature fusion from pretrained audio models. The proposed framework, Diverse Semantic-based Feature Fusion SVC (DSFF-SVC), shows enhanced performance under challenging conditions by integrating features from various models.\n" +
    "  \n" +
    "- **Hardware and Technology Summary**:\n" +
    "  - **NVIDIA Tesla V100 GPU**: Used for training and inference, enabling efficient computation and performance analysis.\n" +
    "  - **Efficiency Metrics**: Evaluated on Tesla V100 for training and inference latency.\n" +
    "\n" +
    "#### *Boundary-Refined Prototype Generation: A General End-to-End Paradigm for Semi-Supervised Semantic Segmentation*\n" +
    "\n" +
    "- **Abstract Summary**: The paper presents Boundary-Refined Prototype Generation (BRPG), an improved method for semi-supervised semantic segmentation that integrates online clustering into the training process. This approach refines prototype generation, enhancing classification accuracy and robustness across various datasets and segmentation networks.\n" +
    "  \n" +
    "- **Hardware and Technology Summary**:\n" +
    "  - **NVIDIA Tesla V100 GPUs**: Four GPUs were used for all experiments, supporting mixed precision training to handle memory limitations.\n" +
    "  - **Large Crop Sizes**: Handled large image sizes for datasets like PASCAL VOC 2012 and MS COCO (513x513 pixels) and Cityscapes (769x769 pixels).\n" +
    "\n" +
    "### Key Insights:\n" +
    "- **NVIDIA Tesla V100 and RTX 6000 GPUs**: These GPUs are recurrently used across various papers for their high computational power, reducing training and evaluation time significantly.\n" +
    "- **CUDA Acceleration**: Enhances the efficiency of complex computations in tasks like ray marching and deep neural network training.\n" +
    "- **Hardware-Facilitated Efficiency**: Numerous experiments emphasize the role of advanced GPUs in managing large data and complex tasks, illustrating the importance of powerful hardware in cutting-edge AI research."


export type SummaryOption = {
    summaryIndex: number;
    summaryContextIndex: number;
}

const SummaryStream = ({ isSubjectLoading, dashboardId, subjectData }: DashboardViewProps) => {
    const [summaries, setSummaries] = useState<ContextResult[]>([]);
    const [dateSummaries, setDateSummaries] = useState<ContextResult[]>([]);
    const [isSummaryLoaded, setIsSummaryLoaded] = useState<boolean>(false);
    const [summarySelections, setSummarySelections] = useState<SummaryOption[]>([]);
    const [dateApi, setDateApi] = useState<string | null>(null);
    const [selectedDate, setSelectedDate] = useState<Date>();
    const [inputDateValue, setInputDateValue] = useState<string>("");
    const [isDateError, setIsDateError] = useState<boolean>(false);


    const getContextInputLabel = (input: string) => {
        return input.charAt(0).toUpperCase() + input.slice(1);
    }

    const getSummarySelectedOption = (index: number) => {
        const summarySelection = summarySelections ? summarySelections.find(item => item.summaryIndex === index) : null;
        if (!summarySelection) {
            return 0
        }
        return summarySelection.summaryContextIndex
    }

    const onSummarySelectedOptionChange = (summaryIndex: number, inputIndex: number | string | undefined | null) => {
        let inputIndexNumber: number
        if (inputIndex === null || inputIndex === undefined) {
            inputIndexNumber = 0
        } else {
            inputIndexNumber = typeof inputIndex === 'string' ? parseInt(inputIndex) : inputIndex
        }
        const summarySelection = summarySelections.find(item => item.summaryIndex === summaryIndex)
        let summarySelected: SummaryOption
        if (!summarySelection) {
            summarySelected = {
                summaryIndex: summaryIndex,
                summaryContextIndex: inputIndexNumber,
            }
            summarySelections.push(summarySelected)
        } else {
            summarySelection.summaryIndex = summaryIndex
            summarySelection.summaryContextIndex = inputIndexNumber
        }
        setSummarySelections([...summarySelections])
    }

    const createChangeHandler = (index) => (e) => onSummarySelectedOptionChange(index, e.target.value)

    const {
        isLoading,
        data,
        error,
        execute,
    } = useGetRecentContextResults();

    const {
        isLoading: isDateLoading,
        data: dateData,
        error: dateError,
        execute: dateExecute,
    } = useGetContextResultByDate();

    useEffect( () => {
        if (!isSubjectLoading) {
            execute(dashboardId).then((response) => {
                setSummaries(response?.summaries ?? [])
                const newSummarySelections: SummaryOption[] = []
                let index = 0
                if (response?.summaries) {
                    for (const summary of response.summaries) {
                        newSummarySelections.push({
                            summaryIndex: index,
                            summaryContextIndex: 0
                        })
                        index++
                    }
                }
                setSummarySelections(newSummarySelections)
                setIsSummaryLoaded(true)
            })
        }
    }, [dashboardId, isSubjectLoading])


    useEffect( () => {
        if (dateApi === null) {
            return;
        }
        if (!isSubjectLoading) {
            setIsSummaryLoaded(false)
            dateExecute(dashboardId, dateApi).then((response) => {
                setDateSummaries(response?.summaries ?? [])
                const newSummarySelections: SummaryOption[] = []
                let index = 0
                if (response?.summaries) {
                    for (const summary of response.summaries) {
                        newSummarySelections.push({
                            summaryIndex: index,
                            summaryContextIndex: 0
                        })
                        index++
                    }
                }
                setSummarySelections(newSummarySelections)
                setIsSummaryLoaded(true)
            })
        }
    }, [dashboardId, dateApi, isSubjectLoading])


    const loadingJsx = (
        <div className={"flex items-center"}>
            <div className={"m-auto pt-16 pb-16"}>
                <Spinner width={200} height={200} />
            </div>
        </div>
    );

    const noSummariesJsx = (message: string) => {
        return (
            <div
                className={
                    "m-auto pt-16 pb-16 text-center text-xl text-semi-bold text-gray-400"
                }
            >
                {message}
            </div>
        );
    };

    const getSummaryRender = () => {
        if (subjectData?.doEnableArxiv) {
            return <OpenAiResponseText text={sampleSummary}  className={"p-1"}  elementClassName={""} />
        }
        if (!isSummaryLoaded || isSubjectLoading) {
            return loadingJsx;
        }
        if ((dateApi ? dateError : error) === ErrorType.NotFound) {
            return noSummariesJsx('no insights found')
        }
        if ((dateApi ? dateError : error)) {
            return noSummariesJsx('an error occurred while retrieving insights')
        }
        if (!(dateApi ? dateSummaries : summaries) || (dateApi ? dateSummaries : summaries).length === 0) {
            return noSummariesJsx('no insights found')
        }
        const summaryItems = []
        let index = 0;
        for (const summary of (dateApi ? dateSummaries : summaries)) {
            const startDate = getDateFromApiString(summary.start_date).toLocal()
            const endDate = getDateFromApiString(summary.end_date).toLocal()
            const startDateText = `${startDate.monthShort} ${startDate.day}, ${startDate.year} ${startDate.toFormat("h:mm a")}`
            const endDateText = `${endDate.monthShort} ${endDate.day}, ${endDate.year} ${endDate.toFormat("h:mm a")}`
            const contextFields = [];
            let fieldIndex = 0;
            if (summary.context_fields && summary.context_fields.length > 0) {
                for (const field of summary.context_fields) {
                    fieldIndex++
                    contextFields.push(
                        <div key={`field-${fieldIndex}`}>
                            <h1 className={"text-xl font-bold"}>{field.context_label}</h1>
                            <div>
                                <OpenAiResponseText text={field.context_output}  className={"p-1"}  elementClassName={""}/>
                            </div>
                        </div>
                    )
                }
            }
            const summaryInputOptions = []
            let summarySelectJsx = null
            let summaryTextJsx = null
            if (subjectData?.summaryContext === SummaryType.SUMMARY_CONTEXT_SENTIMENT_CHAIN || subjectData?.summaryContext === SummaryType.SUMMARY_SENTIMENT_CHAIN) {
                const selectedOption = getSummarySelectedOption(index)
                let inputIndex = 0
                for (const summaryItem of summary.context_outputs) {
                    summaryInputOptions.push(
                        <option key={summaryItem.input_type} value={inputIndex} >{getContextInputLabel(summaryItem.input_type)}</option>
                    )
                    inputIndex++
                }
                summarySelectJsx = (
                    <>
                        <div className={"text-center"}>
                            <label className={"text-gray-500 text-md"}>Sentiment</label>
                        </div>
                        <select
                            className={"mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md font-normal"}
                            onChange={createChangeHandler(index)}
                            defaultValue={selectedOption}
                        >
                            {summaryInputOptions}
                        </select>
                    </>
                )
                summaryTextJsx = (
                    <OpenAiResponseText text={summary.context_outputs[selectedOption].output}  className={"p-1"}  elementClassName={""}/>
                )
            } else {
                summaryTextJsx = (
                    <OpenAiResponseText text={summary.context_outputs[0].output}  className={"p-1"}  elementClassName={""}/>
                )
            }
            summaryItems.push(
                <div key={`summary-${index}`} className={"border-b-2 border-gray-100 pb-4 pt-2"}>
                    <div className={"pb-4 flex"}>
                        <div className={"flex-grow pt-2"}>
                            <h1 className={"text-xl font-bold "}>Insights</h1>
                            <div className={"text-gray-500 text-md pb-2 pt-2"}>
                                Using data from {startDateText} to {endDateText}
                            </div>
                        </div>
                        <div>
                            {summarySelectJsx}
                        </div>
                    </div>
                    <div>
                        {summaryTextJsx}
                    </div>
                    {contextFields}
                </div>
            )
            index++
        }
        return summaryItems
    }

    const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        setInputDateValue(e.currentTarget.value);
        if (e.currentTarget.value.length < 10) {
            setSelectedDate(undefined);
            return;
        }
        const date = parse(e.currentTarget.value, "y-MM-dd", new Date());
        if (isValid(date)) {
            setSelectedDate(date);
            setDateApi(e.currentTarget.value);
        } else {
            setIsDateError(true);
            setSelectedDate(undefined);
        }
    };

    const handleDaySelect = (
        closeCallBack: () => void
    ): SelectSingleEventHandler => {
        return (date, selectedDay, activeModifiers, e) => {
            setSelectedDate(date);
            if (date) {
                const dateString = format(date, "y-MM-dd");
                setInputDateValue(dateString);
                setIsDateError(false);
                setDateApi(dateString);
                closeCallBack();
            } else {
                setInputDateValue("");
            }
        };
    };

    const resetDate = () => {
        setInputDateValue("");
        setSelectedDate(undefined);
        setIsDateError(false);
        setDateApi(null);
    };

    const getCalendar = () => {
        let inputClass =
            "shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md";
        if (isDateError) {
            inputClass =
                "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md";
        }
        return (
            <div className={"flex float-right"}>
                <div className={"flex-1 mt-1"}>
                    <label htmlFor="date" className="sr-only">
                        Enter Date in YYYY-MM-DD format
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                            type="text"
                            name="date"
                            id="date"
                            className={inputClass}
                            placeholder={format(new Date(), "y-MM-dd")}
                            value={inputDateValue}
                            onChange={handleInputChange}
                            aria-invalid="true"
                            aria-describedby={isDateError ? "email-error" : undefined}
                        />
                        {isDateError ? (
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                <ExclamationCircleIcon
                                    className="h-5 w-5 text-red-500"
                                    aria-hidden="true"
                                />
                            </div>
                        ) : null}
                    </div>
                    {isDateError ? (
                        <p className="mt-1 text-sm text-red-600 absolute" id="email-error">
                            Invalid date (YYYY-MM-DD format)
                        </p>
                    ) : null}
                </div>
                <div>
                    <Popover className="relative">
                        {({ open, close }) => (
                            <>
                                <Popover.Button
                                    className={`
                ${open ? "" : "text-opacity-90"}
                mb-0 pb-0 focus:outline-none group inline-flex items-center rounded-md px-3 py-2 text-base font-medium text-white hover:text-opacity-100 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                                >
                                    <CalendarIcon
                                        className={
                                            "h-9 w-9 text-blue-600 rounded-md focus:ring-blue-500 focus:border-blue-500"
                                        }
                                    />
                                </Popover.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="opacity-0 translate-y-1"
                                    enterTo="opacity-100 translate-y-0"
                                    leave="transition ease-in duration-150"
                                    leaveFrom="opacity-100 translate-y-0"
                                    leaveTo="opacity-0 translate-y-1"
                                >
                                    <Popover.Panel className="absolute left-1/2 z-10 mt-3 -translate-x-full transform px-4 sm:px-0">
                                        <div className="overflow-hidden bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                            <DayPicker
                                                initialFocus={open}
                                                mode="single"
                                                defaultMonth={selectedDate}
                                                selected={selectedDate}
                                                onSelect={handleDaySelect(close)}
                                            />
                                        </div>
                                    </Popover.Panel>
                                </Transition>
                            </>
                        )}
                    </Popover>
                </div>
            </div>
        );
    };

    const getResetDateButton = () => {
        if (dateApi) {
            return (
                <button
                    type={"button"}
                    className="inline-flex items-center px-4 py-2  border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    onClick={() => resetDate()}
                >
                    Reset Date
                </button>
            )
        }
        return null
    }

    return (
        <TileBgNone>
            <div className={"flex pt-1 pb-5"}>
                <div className={"flex-initial pt-3"}>
                    <h2 className={"text-xl"}>Insights</h2>
                </div>
                <div className={"flex-grow"}>{getCalendar()}</div>
            </div>
            <TileRounded
                classArray={["max-h-[950px]", "overflow-auto"]}
                tileType={"inner"}
            >
                <div className={"min-h-[250px] p-2"}>
                    {getResetDateButton()}
                    {getSummaryRender()}
                </div>
            </TileRounded>
        </TileBgNone>
    );
};

export default SummaryStream;
