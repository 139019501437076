import {API_ENDPOINT, ErrorType, getErrorFromResponse, getFetchAuthHeaders} from "../../util/api"
import React, { useCallback, useContext, useState } from "react";
import { AuthenticatedUserData, UserContext } from "../user/WithAuth";
import {RecentTweetContextResultResponse, TweetContextResultByDateResponse} from "../../models/twitterApi.model";


export type GetRecentContextResultResponse = {
    error: null | ErrorType;
    execute: (subjectId: number, sinceMinutes?: number) => Promise<RecentTweetContextResultResponse | null>;
    isLoading: boolean;
    data: RecentTweetContextResultResponse | null;
};


export type GetContextResultByDateResponse = {
    error: null | ErrorType;
    execute: (subjectId: number, date: string) => Promise<TweetContextResultByDateResponse | null>;
    isLoading: boolean;
    data: TweetContextResultByDateResponse | null;
};


export async function getSubjectContextResultByDate(
    userData: AuthenticatedUserData,
    subjectId: number,
    date: string
): Promise<TweetContextResultByDateResponse> {
    let endpoint = `${API_ENDPOINT}/v1/twitter/context/getSubjectContextResults/byDate/${subjectId}/${date}`
    const response = await fetch(
        endpoint,
        await getFetchAuthHeaders(userData)
    );
    const responseData = await response.json() as TweetContextResultByDateResponse;
    responseData.responseStatus = response.status;
    return responseData;
}


export async function getRecentSubjectContextResult(userData: AuthenticatedUserData, subjectId: number, sinceMinutes: number = 0): Promise<RecentTweetContextResultResponse> {
    let endpoint = `${API_ENDPOINT}/v1/twitter/context/getSubjectContextResults/recent/${subjectId}`
    if (sinceMinutes) {
        endpoint = `${endpoint}?since_minutes=${sinceMinutes}`
    }
    const response = await fetch(
        endpoint,
        await getFetchAuthHeaders(userData)
    );
    const responseData = await response.json() as RecentTweetContextResultResponse;
    responseData.responseStatus = response.status;
    return responseData;
}

export const useGetContextResultByDate = (): GetContextResultByDateResponse => {
    const [error, setError] = useState<ErrorType | null>(null);
    const [data, setData] = useState<TweetContextResultByDateResponse | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const userData = useContext(UserContext) as AuthenticatedUserData
    const execute = async (subjectId: number, date: string) => {
        try {
            setIsLoading(true);
            const response = await getSubjectContextResultByDate(userData, subjectId, date);
            const responseError = getErrorFromResponse(response.responseStatus);
            if (responseError) {
                setData(null)
                setError(responseError)
                setIsLoading(false);
                return null;
            }
            setData(response);
            setIsLoading(false);
            return response;
        } catch (e) {
            setData(null)
            setError(ErrorType.ClientError);
            setIsLoading(false);
            return null;
        }
    };

    return {
        isLoading,
        error,
        data,
        execute: useCallback(execute, []),
    };
}

export const useGetRecentContextResults = (): GetRecentContextResultResponse => {
    const [error, setError] = useState<ErrorType | null>(null);
    const [data, setData] = useState<RecentTweetContextResultResponse | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const userData = useContext(UserContext) as AuthenticatedUserData
    const execute = async (subjectId: number, sinceMinutes: number = 0) => {
        try {
            setIsLoading(true);
            const response = await getRecentSubjectContextResult(userData, subjectId, sinceMinutes);
            const responseError = getErrorFromResponse(response.responseStatus);
            if (responseError) {
                setData(null)
                setError(responseError)
                setIsLoading(false);
                return null;
            }
            setData(response);
            setIsLoading(false);
            return response;
        } catch (e) {
            setData(null)
            setError(ErrorType.ClientError);
            setIsLoading(false);
            return null;
        }
    };

    return {
        isLoading,
        error,
        data,
        execute: useCallback(execute, []),
    };
};
