import React, { SyntheticEvent } from "react";
import {TweetBase} from "../../../models/twitter.model";
import {getDateFromApiString} from "../../../util/api";
const { default: TwitterIcon } =
  require("../../../images/social/twitter-circled-240.svg") as {
    default: string;
  };
const { default: DefaultUserIcon } =
  require("../../../images/user-128.svg") as {
    default: string;
  };

type TweetProps = {
  tweetData: TweetBase;
  wrapperClass: string;
};

const replaceImageDefault = (e: SyntheticEvent<HTMLImageElement>) => {
  const img = e.target as HTMLImageElement;
  img.onerror = () => {};
  img.src = DefaultUserIcon;
};

const Tweet = ({ tweetData, wrapperClass }: TweetProps) => {

  const getRenderFromV2Data = () => {
    const userData  = tweetData.user!
    const userImagePath = userData.profile_image_url_https;
    const handle = userData.handle;
    const userName = userData.name;
    const tweetTime = getDateFromApiString(tweetData.created_at).toLocal();
    return (
      <div className={wrapperClass}>
        <div className={"flex pt-2 pb-2"}>
          <div className={"flex-none pr-2 pt-1"}>
            <a href={`https://twitter.com/${handle}`} target={"_blank"}>
              <img
                width={48}
                height={48}
                onError={replaceImageDefault}
                src={userImagePath}
                className={"rounded-full bg-blue-300"}
              />
            </a>
          </div>
          <div className={"flex-initial pr-4"}>
            <div className={"text-lg font-semibold"}>{userName}</div>
            <div>
              <a
                className={"text-blue-700 hover:text-blue-900"}
                href={`https://twitter.com/${handle}`}
                target={"_blank"}
              >
                @{handle}
              </a>
            </div>
          </div>
          <div className={"flex-initial text-gray-400 text-sm pt-1 pl-1 lg:pl-4"}>
            <div className={"pb-1"}>{tweetTime.toFormat("h:mm a")}</div>
            <div>
              {tweetTime.monthShort} {tweetTime.day}, {tweetTime.year}
            </div>
          </div>
          <div className={"flex-grow pt-2"}>
            <a
              className={"inline-block float-right"}
              target={"_blank"}
              href={`https://twitter.com/i/web/status/${tweetData.tweet_id}`}
            >
              <img width={45} height={45} src={TwitterIcon} />
            </a>
          </div>
        </div>
        <div className={"p-2 lg:p-6"}>{tweetData.tweet_text}</div>
      </div>
    );
  }

  const getRenderFromMeltData = () => {
    const handle = tweetData.handle
    const tweetTime = getDateFromApiString(
      tweetData.created_at
    ).toLocal();
    return (
      <div className={wrapperClass}>
        <div className={"flex pt-2 pb-2"}>
          <div className={"flex-none pr-2 pt-1"}>
            <a href={`https://twitter.com/${handle}`} target={"_blank"}>
              <img
                width={48}
                height={48}
                src={DefaultUserIcon}
                className={"rounded-full bg-blue-300"}
              />
            </a>
          </div>
          <div className={"flex-initial pr-4"}>
            <div className={"text-lg font-semibold"}>{handle}</div>
            <div>
              <a
                className={"text-blue-700 hover:text-blue-900"}
                href={`https://twitter.com/${handle}`}
                target={"_blank"}
              >
                @{handle}
              </a>
            </div>
          </div>
          <div className={"flex-initial text-gray-400 text-sm pt-1 pl-1 lg:pl-4"}>
            <div className={"pb-1"}>{tweetTime.toFormat("h:mm a")}</div>
            <div>
              {tweetTime.monthShort} {tweetTime.day}, {tweetTime.year}
            </div>
          </div>
          <div className={"flex-grow pt-2"}>
            <a
              className={"inline-block float-right"}
              target={"_blank"}
              href={`https://twitter.com/i/web/status/${tweetData.tweet_id}`}
            >
              <img width={45} height={45} src={TwitterIcon} />
            </a>
          </div>
        </div>
        <div className={"p-2 lg:p-6"}>{tweetData.tweet_text}</div>
      </div>
    );
  }

  if (tweetData.is_v2 && tweetData.user) {
    return getRenderFromV2Data()
  } else {
    return getRenderFromMeltData()
  }
};

export default Tweet;
