import {API_ENDPOINT, ErrorType, getErrorFromResponse, getFetchAuthHeaders} from "../../../util/api"
import React, { useCallback, useContext, useState } from "react";
import { AuthenticatedUserData, UserContext } from "../../user/WithAuth";
import {RecentTweetResponse, TweetByDateResponse} from "../../../models/twitterApi.model";


export type GetRecentTweetResponse = {
    error: null | ErrorType;
    execute: (
        subjectId: number,
        topicId?: number | null,
        sentiment?: string | null,
        sinceMinutes?: number
    ) => Promise<RecentTweetResponse | null>;
    isLoading: boolean;
    data: RecentTweetResponse | null;
};


export type GetTweetByDateResponse = {
    error: null | ErrorType;
    execute: (
        subjectId: number,
        date: string,
        offset?: number | null,
        topicId?: number | null,
        sentiment?: string | null
    ) => Promise<TweetByDateResponse | null>;
    isLoading: boolean;
    data: TweetByDateResponse | null;
};


export async function getSubjectTweetsByDate(
    userData: AuthenticatedUserData,
    subjectId: number,
    date: string,
    offset: number | null = null,
    topicId: number | null = null,
    sentiment: string | null = null
): Promise<TweetByDateResponse> {
    let endpoint = `${API_ENDPOINT}/v1/twitter/tweet/getSubjectTweets/byDate/${subjectId}/${date}`
    let querySeparator = '?'
    if (offset) {
        endpoint = `${endpoint}${querySeparator}offset=${offset}`
        querySeparator = '&'
    }
    if (topicId) {
        endpoint = `${endpoint}${querySeparator}topic_id=${topicId}`
        querySeparator = '&'
    }
    if (sentiment) {
        endpoint = `${endpoint}${querySeparator}sentiment=${sentiment}`
    }
    const response = await fetch(
        endpoint,
        await getFetchAuthHeaders(userData)
    );
    const responseData = await response.json() as TweetByDateResponse;
    responseData.responseStatus = response.status;
    return responseData;
}


export async function getRecentSubjectTweets(
    userData: AuthenticatedUserData,
    subjectId: number,
    topicId: number | null = null,
    sentiment: string | null = null,
    sinceMinutes: number = 0,
): Promise<RecentTweetResponse> {
    let endpoint = `${API_ENDPOINT}/v1/twitter/tweet/getSubjectTweets/recent/${subjectId}`
    let querySeparator = '?'
    if (sinceMinutes) {
        endpoint = `${endpoint}${querySeparator}since_minutes=${sinceMinutes}`
        querySeparator = '&'
    }
    if (topicId) {
        endpoint = `${endpoint}${querySeparator}topic_id=${topicId}`
        querySeparator = '&'
    }
    if (sentiment) {
        endpoint = `${endpoint}${querySeparator}sentiment=${sentiment}`
    }
    const response = await fetch(
        endpoint,
        await getFetchAuthHeaders(userData)
    );
    const responseData = await response.json() as RecentTweetResponse;
    responseData.responseStatus = response.status;
    return responseData;
}

export const useGetTweetsByDate = (): GetTweetByDateResponse => {
    const [error, setError] = useState<ErrorType | null>(null);
    const [data, setData] = useState<TweetByDateResponse | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const userData = useContext(UserContext) as AuthenticatedUserData
    const execute = async (
        subjectId: number,
        date: string,
        offset: number | null = null,
        topicId: number | null = null,
        sentiment: string | null = null
    ) => {
        try {
            setIsLoading(true);
            const response = await getSubjectTweetsByDate(userData, subjectId, date, offset, topicId, sentiment);
            const responseError = getErrorFromResponse(response.responseStatus);
            if (responseError) {
                setData(null)
                setError(responseError)
                setIsLoading(false);
                return null;
            }
            setData(response);
            setIsLoading(false);
            return response;
        } catch (e) {
            setData(null)
            setError(ErrorType.ClientError);
            setIsLoading(false);
            return null;
        }
    };

    return {
        isLoading,
        error,
        data,
        execute: useCallback(execute, []),
    };
}

export const useGetRecentTweetData = (): GetRecentTweetResponse => {
    const [error, setError] = useState<ErrorType | null>(null);
    const [data, setData] = useState<RecentTweetResponse | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const userData = useContext(UserContext) as AuthenticatedUserData
    const execute = async (
        subjectId: number,
        topicId: number | null = null,
        sentiment: string | null = null,
        sinceMinutes: number = 0
        ) => {
        try {
            setIsLoading(true);
            const response = await getRecentSubjectTweets(userData, subjectId, topicId, sentiment, sinceMinutes);
            const responseError = getErrorFromResponse(response.responseStatus);
            if (responseError) {
                setData(null)
                setError(responseError)
                setIsLoading(false);
                return null;
            }
            setData(response);
            setIsLoading(false);
            return response;
        } catch (e) {
            setData(null)
            setError(ErrorType.ClientError);
            setIsLoading(false);
            return null;
        }
    };

    return {
        isLoading,
        error,
        data,
        execute: useCallback(execute, []),
    };
};
