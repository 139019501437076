import React, {useEffect, useState} from "react";
import TileRounded from "../layout/TileRounded";
import { PieChart } from "react-minimal-pie-chart";
import {DashboardViewProps} from "../dashboards/dashboardTypes";
import {ErrorType} from "../../util/api";
import {useGetTopicStats} from "./getTopics";
import {SubjectTopicStatsResponse} from "../../models/subjectApi.model";
import Spinner from "../animation/Spinner";

interface TopicColor {
    arcColor: string;
    bgColor: string;
}

const topics: TopicColor[] = [
    {
        arcColor: "rgb(239 68 68)",
        bgColor: "rgb(254 202 202)",
    },
    {
        arcColor: "rgb(59 130 246)",
        bgColor: "rgb(191 219 254)",
    },
    {
        arcColor: "rgb(20 184 166)",
        bgColor: "rgb(153 246 228)",
    },
    {
        arcColor: "rgb(139 92 246)",
        bgColor: "rgb(221 214 254)",
    },
    {
        arcColor: "rgb(132 204 22)",
        bgColor: "rgb(217 249 157)",
    },
    {
        arcColor: "rgb(234,88,12)",
        bgColor: "rgb(254,215,170)",
    },
    {
        arcColor: "rgb(219,39,119)",
        bgColor: "rgb(251,207,232)",
    },
    {
        arcColor: "rgb(234,179,8)",
        bgColor: "rgb(254,240,138)",
    },
    {
        arcColor: "rgb(6,182,212)",
        bgColor: "rgb(165,243,252)",
    },
    {
        arcColor: "rgb(79,70,229)",
        bgColor: "rgb(199,210,254)",
    }
];

const getTopicColorFromIndex = (index: number): TopicColor  => {
    let colorIndex: number = index
    if (index > 9) {
        colorIndex = index % 10
    }
    return topics[colorIndex]
}


const Topics = ({ isSubjectLoading, dashboardId }: DashboardViewProps) => {

    const [isTopicsLoaded, setIsTopicsLoaded] = useState<boolean>(false);
    const [topicsData, setTopicsData] = useState<SubjectTopicStatsResponse | null>(null);
    const {
        isLoading,
        data,
        error,
        execute,
    } = useGetTopicStats();

    useEffect(() => {
        if (!isSubjectLoading) {
            execute(dashboardId).then((response) => {
                setTopicsData(response ?? null)
                setIsTopicsLoaded(true)
            })
        }
    }, [isSubjectLoading, dashboardId]);

    const loadingJsx = (
        <div className={"flex items-center"}>
            <div className={"m-auto pt-16 pb-16"}>
                <Spinner width={200} height={200} />
            </div>
        </div>
    );

    const noSummariesJsx = (message: string) => {
        return (
            <div
                className={
                    "m-auto pt-16 pb-16 text-center text-xl text-semi-bold text-gray-400"
                }
            >
                {message}
            </div>
        );
    };

    const getCharts = (): JSX.Element[] | JSX.Element => {

        const defaultClass = "flex p-4 pt-6"
        const manyTopicsClass = "grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 pt-4"

        if (!isTopicsLoaded || isSubjectLoading) {
            return (<div className={defaultClass}>{loadingJsx}</div>);
        }
        if (error === ErrorType.NotFound) {
            return (<div className={defaultClass}>{noSummariesJsx('no topics found')}</div>);
        }
        if (error) {
            return (<div className={defaultClass}>{noSummariesJsx('an error occurred while retrieving topics')}</div>)
        }
        if (!topicsData) {
            return (<div className={defaultClass}>{noSummariesJsx('no topics found')}</div>)
        }

        const charts = [];
        let index = 0;
        for (const topic of topicsData.topics) {
            const percentage = topicsData.total_mentions ? ((topic.mentions / topicsData.total_mentions) * 100) : 0
            const empty = 100 - percentage;
            const topicColor = getTopicColorFromIndex(index)
            charts.push(
                <div key={`topic-${topic.id}`}>
                    <div className={"px-3 pb-2"}>
                        <PieChart
                            data={[
                                { value: percentage, color: topicColor.arcColor, display: true },
                                { value: empty, color: topicColor.bgColor },
                            ]}
                            totalValue={100}
                            startAngle={-90}
                            lineWidth={20}
                            label={({ dataEntry }) =>
                                dataEntry.display ? `${dataEntry.value.toFixed(2)}%` : null
                            }
                            labelStyle={{
                                fontSize: "12px",
                                fontFamily: "sans-serif",
                                fill: "black",
                            }}
                            labelPosition={0}
                        />
                    </div>
                    <div className={"text-sm text-center text-gray-500"}>
                        <span>{topic.label}</span>
                    </div>
                </div>
            );
            index++
        }
        return (<div className={index >= 4 ? manyTopicsClass : defaultClass}>{charts}</div>);
    }

    return (
        <TileRounded tileType={"main"}>
            <span className={"text-xl"}>Topics Discussed (Last 7 Days)</span>
            {getCharts()}
        </TileRounded>
    );
};

export default Topics;
