import {SubjectApiData} from "./subjectApi.model";

export type ContentDataType = {
    data_type: string;
    label: string;
    id: number;
}

export const AllContentDataTypes: ContentDataType[] = [
    {
        data_type: 'tweet',
        label: 'X (Twitter)',
        id: 1
    },
    {
        data_type: 'discussions',
        label: 'Forums',
        id: 2
    },
    {
        data_type: 'custom_upload',
        label: 'Custom Source',
        id: 3
    },
    {
        data_type: 'reddit',
        label: 'Reddit',
        id: 4
    },
    {
        data_type: 'blogs',
        label: 'Blogs',
        id: 5
    },
    {
        data_type: 'news',
        label: 'News',
        id: 6
    },
    {
        data_type: 'arxiv_paper',
        label: 'Arxiv Paper',
        id: 7
    },
]

export const getContentDataTypeById = (id: number): ContentDataType | null  => {
    const foundType = AllContentDataTypes.find(dataType => dataType.id === id);
    if (!foundType) {
        return null
    }
    return foundType;
}

export const getEnabledContentDataTypes = (subject: SubjectApiData): ContentDataType[] => {
    if (!subject) {
        return []
    }
    const enabledTypes = []
    if (!subject.doDisableTwitterData) {
        enabledTypes.push(getContentDataTypeById(1))
    }
    if (subject.doEnableReddit) {
        enabledTypes.push(getContentDataTypeById(4))
    }
    if (subject.doEnableDiscussions) {
        enabledTypes.push(getContentDataTypeById(2))
    }
    if (subject.doEnableBlogs) {
        enabledTypes.push(getContentDataTypeById(5))
    }
    if (subject.doEnableNews) {
        enabledTypes.push(getContentDataTypeById(6))
    }
    if (subject.doEnableArxiv) {
        enabledTypes.push(getContentDataTypeById(7))
    }
    return enabledTypes
}

export type ContentDataTypeMetric = {
    // unique int value that maps to datatype
    id: number;
    // 'm' represents the total number of mentions.
    m: number;

    // 'pos' stands for positive sentiment mentions.
    pos: number;

    // 'neu' stands for neutral sentiment mentions.
    neu: number;

    // 'neg' stands for negative sentiment mentions.
    neg: number;
}

export type ContentTopicMetric = {
    // topic id
    id: number;
    // mentions
    m: number;
    // 'neg' stands for negative sentiment mentions.
    neg: number;
    // 'pos' stands for positive sentiment mentions.
    pos: number;
    // 'neu' stands for neutral sentiment mentions.
    neu: number;
    // Data type stats (social service breakdowns)
    dt: ContentDataTypeMetric[]
}

/**
 * ContentMetric represents a set of post analytics during a certain timeframe.
 */
export type ContentMetric = {
    // 't' is the date when the metrics were reported, represented as a string.
    t: string;

    // 'm' represents the total number of mentions.
    m: number;

    // 'pos' stands for positive sentiment mentions.
    pos?: number;

    // 'neu' stands for neutral sentiment mentions.
    neu?: number;

    // 'neg' stands for negative sentiment mentions.
    neg?: number;

    // Topics
    tps: ContentTopicMetric[] | null;

    // Data type stats (social service breakdowns)
    dt: ContentDataTypeMetric[]
}

export type ContentMetrics = {
    updated: string;
    metrics: ContentMetric[];
    subject_id: number;
}
