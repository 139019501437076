import React, { SyntheticEvent } from "react";
import {getDateFromApiString} from "../../util/api";
import {ContentTweet} from "../../models/contentApi.model";
const { default: TwitterIcon } =
    require("../../images/social/twitterx-icon.svg") as {
        default: string;
    };
const { default: DefaultUserIcon } =
    require("../../images/user-128.svg") as {
        default: string;
    };

type TweetProps = {
    contentData: ContentTweet;
    wrapperClass: string;
};

const replaceImageDefault = (e: SyntheticEvent<HTMLImageElement>) => {
    const img = e.target as HTMLImageElement;
    img.onerror = () => {};
    img.src = DefaultUserIcon;
};

const ContentTwitterItem = ({contentData, wrapperClass}: TweetProps) => {
    const userData = contentData.author
    const userImagePath = userData.profile_image_url;
    const handle = userData.handle;
    const userName = userData.name;
    const tweetTime = getDateFromApiString(contentData.created_at).toLocal();
    return (
        <div className={wrapperClass}>
            <div className={"flex pt-2 pb-2"}>
                <div className={"flex-none pr-2 pt-1"}>
                    <a href={`https://twitter.com/${handle}`} target={"_blank"}>
                        {
                            userImagePath ? (
                                <img
                                    width={48}
                                    height={48}
                                    onError={replaceImageDefault}
                                    src={userImagePath}
                                    className={"rounded-full bg-blue-300"}
                                />
                            ) : (
                                <img
                                    width={48}
                                    height={48}
                                    src={DefaultUserIcon}
                                    className={"rounded-full bg-blue-300"}
                                />
                            )
                        }
                    </a>
                </div>
                <div className={"flex-initial pr-4"}>
                    <div className={"text-lg font-semibold"}>{userName}</div>
                    <div>
                        <a
                            className={"text-blue-700 hover:text-blue-900"}
                            href={`https://twitter.com/${handle}`}
                            target={"_blank"}
                        >
                            @{handle}
                        </a>
                    </div>
                </div>
                <div className={"flex-initial text-gray-400 text-sm pt-1 pl-1 lg:pl-4"}>
                    <div className={"pb-1"}>{tweetTime.toFormat("h:mm a")}</div>
                    <div>
                        {tweetTime.monthShort} {tweetTime.day}, {tweetTime.year}
                    </div>
                </div>
                <div className={"flex-grow pt-2"}>
                    <a
                        className={"inline-block float-right"}
                        target={"_blank"}
                        href={`https://twitter.com/i/web/status/${contentData.tweet_id}`}
                    >
                        <img width={40} height={40} src={TwitterIcon}/>
                    </a>
                </div>
            </div>
            <div className={"p-2 lg:p-6"}>{contentData.text}</div>
        </div>
    );
};

export default ContentTwitterItem;
